@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  font-family: "Roboto", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

body {
  padding-top: 65px;
}

.App {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  min-height: calc(100vh - 155px);
  padding: 1rem 0.5rem;
  background-color: rgba(248, 249, 250, 1);
}

section {
  width: 100%;
  max-width: 420px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.4);
}

.my_nav-txt-link {
  color: #994d0d;
}
.my_nav-txt-link:hover {
  color: #994d0d;
  font-weight: bold;
  text-decoration: underline;
}
.my_nav-txt-link.active {
  color: #994d0d !important;
  font-weight: bold;
}
.stock-list {
  color: #994d0d !important;
}
.top-hscroll-menu {
  overflow: scroll;
  white-space: nowrap;
}
.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}
.top-hscroll-menu-item {
  display: inline-block;
  color: #994d0d;
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
  text-decoration: none;
}

h1 {
  font-size: 2rem;
  line-height: 2.8rem;
  font-weight: 700;
  color: #994d0d;
}

h2 {
  font-size: 1rem;
  line-height: 1.4rem;
  font-weight: 600;
  color: #994d0d;
}

.hero-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  grid-gap: 1rem;
}
.hero-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}
.featured-article-date {
  color: #653309;
  text-transform: uppercase;
}
.featured-article-image {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}
.featured-article-title {
  font-size: 2rem;
  font-weight: bolder;
  color: #653309;
}
.blog-container {
  margin-top: 3rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  grid-gap: 2rem;
}
.blog-article-date {
  color: #653309;
  text-transform: uppercase;
}
.blog-article-image {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem;
}
.blog-article-title {
  font-size: 2rem;
  font-weight: bolder;
  color: #653309;
}
.view-more {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 10rem;
}
.view-more-text {
  font-weight: bold;
  text-decoration: underline;
}
.footer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 90px;
  background-color: #994d0d;
}
.footer-company-name {
  font-size: 1.1rem;
  font-weight: 600;
  color: whitesmoke;
}
.footer-copyright {
  color: gainsboro;
}
