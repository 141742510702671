.page-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.menu-sidebar {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.menu-title {
  display: flex;
  justify-content: center;
  font-weight: bold;
  border-bottom: 1px solid black;
  padding: 5px;
}
.menu-item {
  padding: 5px;
  cursor: pointer;
}
.menu-item:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.main-admin-content {
  display: flex;
  justify-content: center;
}
.top-action-bar {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}
Table.data-table tbody tr td {
  table-layout: fixed;
  vertical-align: middle !important;
}
td.table-action,
th.table-action {
  text-align: center;
}
