.login-section,
.register-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 190px);
}
.form-container {
  background-color: white;
  padding: 2rem;
  border-radius: 15px;
  width: 25rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.1), 0px 8px 16px rgba(0, 0, 0, 0.1);
}
.login-title,
.register-title {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #994d0d;
}
.button-google {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 8px;
  border-radius: 5px;
}
.other-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.form-buttons {
  display: flex;
  justify-content: space-between;
}
.errmsg {
  background-color: lightpink;
  color: firebrick;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 5px;
}
.offscreen {
  position: absolute;
  left: -9999px;
}

.hide {
  display: none;
}

.valid {
  color: limegreen;
  margin-left: 0.25rem;
}

.invalid {
  color: red;
  margin-left: 0.25rem;
}
#pwdnote {
  font-size: 0.8rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 3px;
  color: palevioletred;
  background-color: whitesmoke;
}
